import { FC } from 'react';
import { DsmGrid } from '@dsm-dcs/design-system-react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import { FormType, isVersionV2AnimalSystem } from '../../common';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import StepperButtons from '../../CommonDataParts/v2.0/StepperButtons';
import lookupValues from '../../../../helpers/lookupValues';
import { objectEntriesSorter } from '../../../../helpers/sortingFunctions';
import { AllBaselinesV2 } from '../../../../models/Baseline';
import { AnimalType } from '../../../../../../graphql/types';
import InterventionResources from './InterventionResources';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import RowControlledTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';
import { unitLong } from '../../../../utils/unit-utils';
import { Typography } from '@material-ui/core';

type AnyEvent = any;
type DsmStyles = any;

interface InterventionProfileProps {
  formType: FormType;
  cancelHandler?: (e: AnyEvent | undefined) => void;
  forwardHandler?: (e: AnyEvent | undefined) => void;
  baseline: AllBaselinesV2 | undefined;
  animalType: AnimalType;
}

export const InterventionProfile: FC<InterventionProfileProps> = ({
  formType = FormType.View,
  cancelHandler = undefined,
  forwardHandler = undefined,
  baseline,
  animalType,
}) => {
  const intl = useIntl();
  const fieldPrefix = 'info';
  const currYear = new Date().getFullYear();
  const years = Object.entries(
    Array.from({ length: 15 }, (_, i) => currYear - i)
  ).map(([i, yearNum]) => ({
    text: yearNum.toString(),
    value: yearNum.toString(),
  }));
  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const { trigger, errors, getValues } = useFormContext();

  // console.log('bl', baseline);
  const checkAndForward = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      const result = await trigger(['info', 'resourceUse']);
      if (result) {
        if (forwardHandler) forwardHandler(true);
      }
    })();
  };

  const skip = () => {
    if (forwardHandler) forwardHandler(false);
  };

  return (
    <>
      <Typography variant='h6' style={{ color: 'black', fontWeight: 500, marginTop: `var(--dsm-spacing-px-20)` }}>
        {intl.formatMessage({ id: "SUSTELL.CURRENT.INTERVENTION.NEW_GENERIC.DATA" })}
      </Typography>
      <Typography variant='body1' color='textPrimary' style={{ marginBottom: '2rem' }}>
        {intl.formatMessage({ id: "SUSTELL.CURRENT.INTERVENTION.NEW_GENERIC.DATA.DESCRIPTION" })}
      </Typography>

      <hr />

      <Typography variant='h6' style={{ color: 'black', fontWeight: 500, }}>
        {intl.formatMessage({ id: "SUSTELL.INTERVENTION.PROFILE" })}
      </Typography>
      <Typography variant='body1' color='textPrimary' style={{ marginBottom: '2rem' }}>
        {intl.formatMessage({ id: "SUSTELL.INTERVENTION.DETAILS.INTRO" })}
      </Typography>

      {formType === 'edit' && (
        <input
          // ref={fc.register()}
          type="hidden"
          name={`${fieldPrefix}.oldName`}
        />
      )}

      <DsmGrid
        className={classes.dsmGridFourFixedColumns}
        style={{ marginTop: `var(--dsm-spacing-px-6)` }}
      >
        <ReactHookDsmInput
          name={`${fieldPrefix}.name`}
          label={intl.formatMessage({ id: 'INTERVENTION.FORM.NAME' })}
          required
          disabled={formType === FormType.View}
        />
        <ReactHookDsmSelect
          name={`${fieldPrefix}.interventionCategory`}
          label={intl.formatMessage({ id: 'INTERVENTION.FORM.CATEGORY' })}
          options={Object.entries(lookupValues.interventionCategories)
            .sort(objectEntriesSorter)
            .map(([i, item]) => ({
              text: item,
              value: i,
            }))}
          disabled={formType === 'view'}
        />
      </DsmGrid>
      <DsmGrid className={classes.dsmGridTwoColumn}>
        <ReactHookDsmInput
          name={`${fieldPrefix}.description`}
          label={`${intl.formatMessage({
            id: 'INTERVENTION.FORM.DESCRIPTION',
          })}`}
          multiline
          disabled={formType === FormType.View}
        />
      </DsmGrid>

      <Typography variant='h6' style={{ color: 'black', fontWeight: 500, marginTop: `var(--dsm-spacing-px-16)`}}>
        {intl.formatMessage({ id: "SUSTELL.PROCESS.DIALOG.RESOURCES.TITLE" })}
      </Typography>
      <Typography variant='body1' color='textPrimary' style={{ marginBottom: '2rem' }}>
        {intl.formatMessage({ id: "SUSTELL.BASELINE.DETAILS.RESOURCES.DESCRIPTION" })}
      </Typography>

      {isVersionV2AnimalSystem(animalType) && (
        <InterventionResources
          formType={formType}
          baseline={baseline}
          animalType={animalType}
          inputType="resourceUse"
        />
      )}
      {!isVersionV2AnimalSystem(animalType) && (
        <DsmGrid
          className={classes.dsmGridTwoColumn}
          style={{ paddingTop: '25px' }}
        >
          <RowControlledTextFieldWithMetrics
            name="resourceUse.electricityUse"
            label={intl.formatMessage({
              id: 'BASELINE.FORM.RESOURCES.ELECTRICITY',
            })}
            type="number"
            tooltip={intl.formatMessage({
              id: 'INTERVENTION.FORM.RESOURCES.ELECTRICITY.TOOLTIP',
            })}
            metricUnit={
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              userUOM?.unitElectricityUse
                ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                unitLong(userUOM.unitElectricityUse as string)
                : 'MJ'
            }
            baseline={baseline?.resourceUse?.electricityUse}
            disabled={formType === FormType.View}
          />
          <RowControlledTextFieldWithMetrics
            name="resourceUse.gasUse"
            label={intl.formatMessage({ id: 'BASELINE.FORM.RESOURCES.GAS' })}
            type="number"
            tooltip={intl.formatMessage({
              id: 'INTERVENTION.FORM.RESOURCES.GAS.TOOLTIP',
            })}
            metricUnit={
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              userUOM?.unitNaturalGasUse
                ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                unitLong(userUOM.unitNaturalGasUse as string)
                : 'MJ'
            }
            baseline={baseline?.resourceUse?.gasUse}
            disabled={formType === FormType.View}
          />
          <RowControlledTextFieldWithMetrics
            name="resourceUse.dieselUse"
            label={intl.formatMessage({
              id: 'BASELINE.FORM.RESOURCES.DIESEL',
            })}
            type="number"
            tooltip={intl.formatMessage({
              id: 'INTERVENTION.FORM.RESOURCES.DIESEL.TOOLTIP',
            })}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            metricUnit={
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              userUOM?.unitDieselUse
                ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                unitLong(userUOM.unitDieselUse as string)
                : 'MJ'
            }
            baseline={baseline?.resourceUse?.dieselUse}
            disabled={formType === FormType.View}
          />
          <RowControlledTextFieldWithMetrics
            name="resourceUse.waterUse"
            label={intl.formatMessage({ id: 'BASELINE.FORM.RESOURCES.WATER' })}
            type="number"
            tooltip={intl.formatMessage({
              id: 'INTERVENTION.FORM.RESOURCES.WATER.TOOLTIP',
            })}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            metricUnit={
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              userUOM?.unitWaterUse
                ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                unitLong(userUOM.unitWaterUse as string)
                : 'liters'
            }
            baseline={baseline?.resourceUse?.waterUse}
            disabled={formType === FormType.View}
          />
        </DsmGrid>
      )}
      <StepperButtons
        continueLabel={intl.formatMessage({ id: 'SUSTELL.SAVE_CONTINUE' })}
        cancelHandler={cancelHandler}
        skipHandler={formType !== 'view' ? skip : undefined}
        continueHandler={checkAndForward}
      />
    </>
  );
};

export default InterventionProfile;
