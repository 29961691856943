/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box } from '@material-ui/core';
import { InterventionStageProps } from '../CommonDataParts/InterventionTypes';
import { AnimalType, StageType } from '../../../../../../graphql/types';
import { ListEntry, ShrimpFarms } from '../../common';
import { ShrimpBaseline } from '../../../../models/Baseline/ShrimpBaseline';
import StageButtonBox from '../../StageButtonBox_v2';

interface ShrimpInterventionStageProps extends Omit<InterventionStageProps, 'manureManagementSystems'> {
  baseline: ShrimpBaseline;
  origins: ListEntry[];
  animalType: AnimalType.LitopenaeusVannamei | AnimalType.PenaeusMonodon;
  farms: ShrimpFarms[];
}

enum StageDialogType {
  INPUT = 'input',
  FEED = 'feed',
  OPERATIONS = 'operations',
  OUTPUT = 'output',
}

const ShrimpInterventionStages = ({
  stageIndex,
  baseline,
  singleIngredients,
  compoundFeeds,
  origins,
  farms,
  formType,
  animalType,
}: ShrimpInterventionStageProps) => {
  const [activeDialog, setActiveDialog] = useState<StageDialogType | null>(null);
  const showDialog = (dialog: StageDialogType | null) => { setActiveDialog(dialog); };
  const fc = useFormContext();
  const stagePrefix = `stages.${stageIndex}`;
  const stageType: StageType = baseline?.stages?.[stageIndex]?.type as StageType;

  return (
    <>
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.id`}
        value={`${baseline?.stages?.[stageIndex]?.id}`}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.name`}
        value={`${baseline?.stages?.[stageIndex]?.name}`}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.type`}
        value={stageType}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.index`}
        value={stageIndex}
      />

      {stageType === StageType.Growing && activeDialog === 'input' &&
        <></>
      }

      {stageType === StageType.Hatching && activeDialog === 'input' &&
        <></>
      }

      {stageType === StageType.Nursery && activeDialog === 'input' &&
        <></>
      }

      {activeDialog === 'feed' &&
        <></>
      }

      {activeDialog === 'operations' &&
        <></>
      }

      {activeDialog === 'output' &&
        <></>
      }

      <Box
        style={{ display: 'flex', width: '80%' }}
        flexDirection="row"
        justifyContent="space-between"
      >
        {stageType === StageType.Growing && <StageButtonBox
          titleCode="BASELINE.FORM.JUVENILES.SHRIMP.JUVENILES"
          iconCode="general/check-heart"
          descriptionTitle="SUSTELL.STAGE.ANIMAL_DETAILS"
          description="SUSTELL.STAGE.INPUT_DATA"
          error={fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.input}
          handleOpen={() => showDialog(StageDialogType.INPUT)}
        />}

        {stageType === StageType.Hatching && <StageButtonBox
          titleCode="BASELINE.FORM.BROODSTOCK.SHRIMP.BROODSTOCK"
          iconCode="general/check-heart"
          descriptionTitle="SUSTELL.STAGE.HATCHING_EGGS_DETAILS"
          description="SUSTELL.STAGE.INPUT_DATA"
          error={fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.input}
          handleOpen={() => showDialog(StageDialogType.INPUT)}
        />}

        {stageType === StageType.Nursery && <StageButtonBox
          titleCode="BASELINE.FORM.POST_LARVAE.SHRIMP.POST_LARVAE"
          iconCode="general/check-heart"
          descriptionTitle="SUSTELL.STAGE.ANIMAL_DETAILS"
          description="SUSTELL.STAGE.INPUT_DATA"
          error={fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.input}
          handleOpen={() => showDialog(StageDialogType.INPUT)}
        />}

        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE"
          iconCode="shapes/cube-02"
          descriptionTitle="SUSTELL.STAGE.FEED"
          description="SUSTELL.STAGE.INPUT_DATA"
          error={fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.feed}
          handleOpen={() => showDialog(StageDialogType.FEED)}
        />

        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.OPERATIONS.TITLE"
          iconCode="general/settings-01"
          descriptionTitle="SUSTELL.STAGE.OUTPUT"
          description="SUSTELL.STAGE.INPUT_DATA"
          error={fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.operations}
          handleOpen={() => showDialog(StageDialogType.OPERATIONS)}
        />

        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE"
          iconCode="arrows/arrow-circle-broken-right"
          descriptionTitle="SUSTELL.STAGE.OUTPUT"
          description="SUSTELL.STAGE.INPUT_DATA"
          error={fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.output}
          handleOpen={() => showDialog(StageDialogType.OUTPUT)}
        />
      </Box>
    </>
  );
};

export default ShrimpInterventionStages;
