import { MenuItem } from '@material-ui/core';
import React from 'react';
// import { useIntl } from 'react-intl';
import { DsmGrid } from '@dsm-dcs/design-system-react';
import { makeStyles } from '@material-ui/styles';

import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { DSM_DISABLED_LABEL, processAndStageStylesV2 } from '../../../../_metronic/layout';
import ReactHookDsmSelect from '../../Helpers/ReactHookDsmSelect2';

const inlineTooltipClass = {
  color: 'var(--dsm-color-neutral-darker)',
  position: 'absolute',
  height: '16px',
  width: '16px',
  top: '29px',
  right: '7px'
}

const useStyles = makeStyles({
  root: {
    // minHeight: '74px',
    '& input': {
      marginTop: '-4px',
      paddingTop: '0px',
      paddingBottom: '0px',
      paddingRight: '24px',
      fontSize: '13px',
      lineHeight: '28px'
    },
    '& label': {
      position: 'relative',
      width: '125% !important',
      paddingLeft: '0px',
      marginBottom: '9px',
      fontSize: '16px',
      fontWeight: '500'
    },
    '& legend': {
      display: 'none'
    },
    '& label.Mui-focused': {
      color: 'var(--dsm-color-primary)',
    },
    '& label.Mui-error': {
      color: 'var(--dsm-color-error)',
    },
    '& .MuiOutlinedInput-root': {
      '& div.MuiInputAdornment-positionEnd': {
        '& p': {
          fontSize: '13px',
          marginBottom: '3px'
        },
      },
      '& fieldset': {
        border: '1px var(--dsm-color-neutral-darker) solid',
        borderRadius: '2px',
      },
      '&.Mui-focused fieldset': {
        border: '1px var(--dsm-color-primary) solid',
      },
      '&.Mui-error fieldset': {
        border: '1px var(--dsm-color-error) solid',
      },
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '0px !important'
    },
  },
});

const RowSelectWithMetricsV2 = props => {
  let {
    name,
    label,
    control,
    options,
    tooltip,
    variant,
    baseline,
    disabled,
    originalChanged = undefined,
    changeHandler = undefined,
  } = props;
  const classes = processAndStageStylesV2();
  const intl = useIntl();
  let originalDisplayName = baseline;
  if (baseline && options?.findIndex(e => e.value === baseline) > -1) {
    originalDisplayName = options.filter(e => e.value === baseline)[0]?.text;
  }

  const labelDisabled = disabled
    ? { color: DSM_DISABLED_LABEL, width: 'max-content' }
    : { width: 'max-content' };

  return (
    <DsmGrid
      className={classes.dsmGridOneColumnNoRowgap}
      style={{ marginBottom: 'var(--dsm-spacing-px-4' }}
    >
      {/* <input hidden name={name} /> */}
      <ReactHookDsmSelect
        name={`${name}`}
        defaultValue={baseline}
        options={options}
        label={label}
        control={control}
        variant={variant}
        fullWidth
        style={{ marginBottom: 0 }}
        tooltip={tooltip || ''}
        disabled={disabled}
        changeHandler={e => {
          // console.log(e.target.value, baseline)

          // if onChange event provided, trigger it
          if (changeHandler) {
            changeHandler(e);
            return;
          }

          if (e.target.value !== baseline && originalChanged) {
            originalChanged(true)
          } else {
            originalChanged(false)
          }
        }}
      >
        {(options || []).map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.text}
          </MenuItem>
        ))}
      </ReactHookDsmSelect>
      <div style={labelDisabled}>
        {intl.formatMessage({ id: "INTERVENTION.FORM.CHANGE.ORIGINAL_VALUE" })}
        <strong>
          {originalDisplayName}
        </strong>
      </div>
    </DsmGrid>
  );
};

export default RowSelectWithMetricsV2;
