/* eslint-disable react/react-in-jsx-scope */
import { API } from "aws-amplify";

import React, { useEffect, useRef, useState } from "react";
import { DsmModal } from "@dsm-dcs/design-system-react";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useHistory } from "react-router";
import { templateStyles, useSubheader } from "../../../../_metronic/layout";
import useAdobeDataLayer from "../../analytics/adobeDataLayer";
import {
  getMasterData,
  listFacilitiesBrief,
  listFarms,
  listFeedsV2,
  listOriginsAndSingleIngredients,
} from "../../../../graphql/queries";
import {
  listEntryDataSorter,
  masterDataSorter,
} from "../../helpers/sortingFunctions";
import {
  NotificationDialog,
  NotificationDialogType,
} from "../../components/helpers/NotificationDialog";
import {
  mapInterventionDataInSustell,
  mapInterventionDataOutSustell,
} from "../../../modules/Farms/Intervention/mapper/interventionDataMapperSustell";
import {
  fetchInterventionDatasetForInterventions,
  fetchPPDatasetV2ForInterventions,
  saveIntervention,
  getFarmAnimalType,
} from "../../apicalls/farmFunctions";
import {
  FormType,
  isVersionV2AnimalSystem,
  isReadOnlyAnimalSystem,
} from "../../components/FarmFlow/common";
import { UserProfilePrefs } from "../../../modules/Helpers/UserProfilePrefs";
import getValidationSchemaSustell from "../../../modules/Farms/Intervention/validationSchema/interventionValidationSchemaAssembler";
import { defaultValues } from "../../components/FarmFlow/Intervention/interventionDefaultValues";
import SustellInterventionSteps from "./SustellInterventionSteps_v2";
import InterventionProfile from "../../components/FarmFlow/Intervention/CommonDataParts/InterventionProfile";
import { mapProductionDataInSustell } from "../../../modules/Farms/Baseline/mapper/productionDataMapper";
import PigInterventionStages from "../../components/FarmFlow/Intervention/Pig_v2/PigInterventionStages_v2";
import MarineFishInterventionStages from "../../components/FarmFlow/Intervention/MarineFish/MarineFishInterventionStages";
import {
  DsmButtonV2,
  useButtonStyles,
} from "../../../sustell_15/components/helpers/DsmButton";
import {
  clearInterventionDraftAction,
  setInterventionDraftAction,
} from "../../../state/drafts/interventionDraftsSlice";
import getInterventionDraftByKey from "../../../state/drafts/interventionGetFromDraft";
import { useIntl } from "../../../../_metronic/i18n/customUseIntl";
import StepperButtons from "../../components/FarmFlow/CommonDataParts/v2.0/StepperButtons";
import {
  isMarineFish,
  isPoultry,
  isPig,
  isShrimp,
  formatTargetSpecies,
} from "../../../sustell_15/helpers/animals";
import lookupValues from "../../helpers/lookupValues";
import paginateQuery from "../../../sustell_15/helpers/paginate";
import PoultryInterventionStages from "../../components/FarmFlow/Intervention/Poultry/PoultryInterventionStages";
import DairyInterventionStages from "../../components/FarmFlow/Intervention/Dairy/DairyInterventionStages";
import { AnimalType } from "../../../../graphql/types";
import LayingHensInterventionStages from "../../components/FarmFlow/Intervention/LayingHens/LayingHensInterventionStages";
import BroilerInterventionStages from "../../components/FarmFlow/Intervention/Broiler/BroilerInterventionStages";
import PigFatteningInterventionStages from "../../../sustell_15/components/FarmFlow/Intervention/PigFattening/PigFatteningInterventionStages";
import SalmonInterventionStages from "../../components/FarmFlow/Intervention/Salmon/SalmonInterventionStages";
import SlaughterhouseInterventionStages from "../../components/FarmFlow/Intervention/Slaughterhouse/SlaughterhouseInterventionStages";
import ShrimpInterventionStages from "../../components/FarmFlow/Intervention/Shrimp/ShrimpInterventionStages";
import { translateIngredients } from "../../utils/translation-utils";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";

const SustellInterventionMainPage_v2 = (props) => {
  const language = useLang();
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const subheader = useSubheader();
  const buttonClasses = useButtonStyles();
  const classes = templateStyles();
  const { customerID, farmID, processID, interventionID } = props.match.params;
  const { formSubmissionEvent, formErrorsEvent } = useAdobeDataLayer();

  const [manureManagementSystems, setManureManagementSystems] = useState([]); // leagacy - when old components removed, this can be removed / no longer fetched anyway
  // would be good to keep this stored in Context
  const farmName = props.location.state?.farmName || "Farm";
  const processName = props.location.state?.processName || "Process";
  const datasetID = props.location.state?.datasetID;
  const duplicateInterventionRef =
    props.location.state?.duplicateInterventionRef;
  const stateAnimalType = props.location.state?.animalType;
  const farmCountry = props.location.state?.farmCountry || "";

  const formType =
    props?.location?.state?.formType ||
    (datasetID === "NEW" || duplicateInterventionRef
      ? FormType.Add
      : FormType.Edit);

  const [poultryFarms, setPoultryFarms] = useState([]);
  const [pigFarms, setPigFarms] = useState([]);
  const [openDraftDialog, setOpenDraftDialog] = useState(false);
  const [baseline, setBaseline] = useState({});
  const [intervention, setIntervention] = useState({});
  const [compoundFeeds, setCompoundFeeds] = useState([]);
  const [singleIngredients, setSingleIngredients] = useState([]);
  const [origins, setOrigins] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [animalType, setAnimalType] = useState(stateAnimalType);
  const [masterDataEnums, setMasterDataEnums] = useState({});
  const [_3NOPCalcChanged, set3NOPCalcChanged] = useState(false);
  const [restoreDraftErrorMessage, setRestoreDraftErrorMessage] = useState("");

  // const [dsmFeedsVisible, setDsmFeedsVisible] = useState(false);
  const [notification, setNotification] = useState(null);

  // const [stageFormOpen, setStageFormOpen] = useState(false);
  // const [selectedStage, setSelectedStage] = useState({});
  // const [stageFormType, setStageFormType] = useState('');
  const [activeStep, setActiveStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);

  const minValueOutput = UserProfilePrefs.getInstance().getMinOutputMass();
  const tempRange = UserProfilePrefs.getInstance().getTempRange();
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();

  const createDraftKey = () => {
    let key = null;
    if (
      datasetID &&
      interventionID &&
      (formType === "edit" || formType === "add")
    ) {
      key = `INTERVENTION_${processID}_${datasetID}_${interventionID}`;
    } else if (formType === "add" && duplicateInterventionRef) {
      key = `INTERVENTION_NEW_${processID}_${datasetID}_${duplicateInterventionRef}`;
    }
    return key;
  };

  const draftKey = createDraftKey();

  async function loadDairyMasterData() {
    API.graphql({
      query: getMasterData,
      variables: {
        customerId: customerID,
        animalType: stateAnimalType,
      },
    }).then(
      (response) => {
        if (response.data?.getMasterData?.compound_feed_uses)
          setCompoundFeeds(
            response.data.getMasterData.compound_feed_uses.sort(
              masterDataSorter
            )
          );
        if (response.data?.getMasterData?.single_ingredient_uses)
          setSingleIngredients(
            response.data?.getMasterData.single_ingredient_uses.sort(
              masterDataSorter
            )
          );
        if (response.data?.getMasterData?.manure_management_system)
          setManureManagementSystems(
            response.data.getMasterData.manure_management_system.sort(
              masterDataSorter
            )
          );
      },
      (reason) => {
        console.log("Error fetching master data: ", reason);
      }
    );
  }
  const loadFacilities = async () => {
    API.graphql({
      query: listFacilitiesBrief,
      variables: {
        customerId: customerID,
      },
    }).then(
      (response) => {
        setFacilities(response.data.listFacilities.items || []);
      },
      (reason) => {
        console.log("err, ", reason);
      }
    );
  };

  const checkFor3NOPError = () => {
    return animalType === "DAIRY" && _3NOPCalcChanged;
  };

  async function loadFarms() {
    API.graphql({
      query: listFarms,
      variables: {
        customerId: customerID,
      },
    }).then((response) => {
      const resultList = [];
      response.data.listFarms.forEach((farm) => {
        farm.animalSystems.items.forEach((animalSystem) => {
          animalSystem.baselines.items.forEach((bas) => {
            // Ignore current opened baseline
            if (
              farm.id !== farmID ||
              animalSystem.id !== processID ||
              bas.reference !== datasetID
            ) {
              bas.stages.forEach((stage) => {
                let stageData = JSON.parse(stage.stageData);
                let outputNames = [];

                try {
                  // shrimp has output as list with names
                  if (isShrimp(animalSystem.animalType))
                    stageData?.output?.forEach((output) => {
                      outputNames.push(output.name);
                    });
                } catch (e) {
                  console.log(e);
                }
                resultList.push({
                  farmId: farm.id,
                  farmName: farm.name,
                  productionProcessId: animalSystem.id,
                  productionProcessName: animalSystem.name,
                  stageId: stage.id,
                  stageName: stage.name,
                  reference: bas.reference,
                  stageType: stage.type,
                  averageWeight: stageData?.output?.averageWeight,
                  averageWeightPiglets:
                    stageData?.animalsOutput?.averageWeightPiglets,
                  averageWeightPigs:
                    stageData?.animalsOutput?.averageWeightPigs,
                  averageAgePigs: stageData?.animalsOutput?.averageAgePigs,
                  animalType: animalSystem.animalType,
                  databaseFoundation: bas.databaseFoundation,
                  outputs: outputNames,
                  benchmark: farm.benchmark,
                });
              });
            }
          });
        });
      });
      setPoultryFarms(
        resultList.filter(
          (item) => isPoultry(item.animalType) && item.animalType === animalType
        )
      );
      setPigFarms(
        resultList.filter(
          (item) => isPig(item.animalType) && item.animalType === animalType
        )
      );
    });
  }

  async function loadVersionOneMasterData() {
    API.graphql({
      query: getMasterData,
      variables: {
        customerId: customerID,
        animalType,
      },
    }).then(
      (response) => {
        if (response.data?.getMasterData?.single_ingredient_uses)
          setSingleIngredients(
            response.data?.getMasterData.single_ingredient_uses.sort(
              masterDataSorter
            )
          );
        if (response.data?.getMasterData?.manure_management_system)
          setManureManagementSystems(
            response.data.getMasterData.manure_management_system.sort(
              masterDataSorter
            )
          );
        // setDsmFeedsVisible(response.data?.getCustomer?.feedsVisible || false);
        if (animalType === "SALMON") {
          if (response.data?.getMasterData?.enumerations) {
            const enumsObject = {};
            response.data.getMasterData.enumerations.forEach((item) => {
              enumsObject[item.enumName] = [...item.enumValues];
            });
            setMasterDataEnums(enumsObject);
          }
          if (response.data?.getCompoundFeedList?.items) {
            //needs to be filtered for animalType
            const result = response.data.getCompoundFeedList.items;
            const availableCompoundFeeds = result
              .filter(
                (item) =>
                  item.target_species.includes("Salmon system") ||
                  item.target_species.includes("Marine fish system") ||
                  item.target_species.includes(
                    "All animal production system types"
                  )
              )
              .sort(masterDataSorter);
            setCompoundFeeds(availableCompoundFeeds);
          }
        }
      },
      (reason) => {
        console.log("Error fetching master data: ", reason);
      }
    );
  }

  const loadCompoundFeeds = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    await paginateQuery(
      {
        query: listFeedsV2,
        variables: {
          customerId: customerID,
          animalType: animalType,
        },
      },
      "listCompoundFeedsV2"
    ).then(
      (response) => {
        setCompoundFeeds(
          // use only feeds for selected animal type
          response?.filter((feed) =>
            feed.targetSpecies?.includes(
              lookupValues.animalTypeFeedAnimalSystem[animalType]
            ) ||
            feed.targetSpecies?.includes(
              formatTargetSpecies(lookupValues.animalTypeFeedAnimalSystem[animalType])
            )
          )
            ?.sort(masterDataSorter)
        );
      },
      (error) => {
        console.log("Error fetching compound feeds: ", error);
      }
    );
  };

  async function loadMasterData() {
    const farmAnimalType = await getFarmAnimalType(farmID, stateAnimalType);
    setAnimalType(farmAnimalType);

    API.graphql({
      query: listOriginsAndSingleIngredients,
      variables: {
        customerId: customerID,
        animalType: farmAnimalType,
      },
    }).then(
      (response) => {
        const originsList =
          response.data.getOrigins?.map((item) => ({
            value: item.isoCode,
            text: item.name,
          })) || [];
        let ingredients =
          response.data.getSingleIngredientNames?.map((item) => ({
            value: item.name,
            text: item.name,
          })) || [];
        setOrigins(originsList.sort(listEntryDataSorter));
        translateIngredients(ingredients, language);
        setSingleIngredients(ingredients?.sort(listEntryDataSorter));
      },
      (reason) => {
        console.log("Error fetching master data: ", reason);
      }
    );

    if (isMarineFish(farmAnimalType)) {
      API.graphql({
        query: getMasterData,
        variables: {
          customerId: customerID,
          animalType: farmAnimalType,
        },
      }).then((response) => {
        if (response.data?.getMasterData?.enumerations) {
          const enumsObject = {};
          response.data.getMasterData.enumerations.forEach((item) => {
            enumsObject[item.enumName] = [...item.enumValues];
          });
          setMasterDataEnums(enumsObject);
        }
      });
    }
  }

  useEffect(() => {
    if (
      animalType !== AnimalType.Dairy &&
      !isReadOnlyAnimalSystem(animalType)
    ) {
      loadCompoundFeeds();
      loadMasterData();
    } else if (
      !isReadOnlyAnimalSystem(animalType) &&
      animalType === AnimalType.Dairy
    ) {
      loadDairyMasterData();
    } else if (isReadOnlyAnimalSystem(animalType)) {
      console.log(isReadOnlyAnimalSystem(animalType), animalType);
      loadVersionOneMasterData();
    }
    loadFacilities();
    if (isPoultry(animalType) || isPig(animalType)) loadFarms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animalType]);

  const setBaselineData = (fetchedBaseline, farmAnimalType) => {
    // console.log("fetchedBaseline:", fetchedBaseline);
    const selBaseline = mapProductionDataInSustell(
      fetchedBaseline,
      farmAnimalType
    );

    setBaseline(selBaseline);
    subheader.setBreadcrumbs([
      {
        pathname: `/customers/${customerID}/myfarms`,
        title: intl.formatMessage({ id: "SUSTELL.MY_FARMS" }),
        state: { animalType: farmAnimalType },
      },
      {
        pathname: `/customers/${customerID}/farm/${farmID}`,
        title: farmName,
        state: { animalType: farmAnimalType },
      },
      {
        pathname: `/customers/${customerID}/farm/${farmID}/pp/${processID}`,
        title: processName,
        state: { animalType: farmAnimalType },
      },
      {
        pathname: `/customers/${customerID}/farm/${farmID}/pp/${processID}/nint/${interventionID}`,
        title:
          intervention?.info?.name ||
          intl.formatMessage({ id: "GENERAL.NEW.INTERVENTION" }),
        state: { animalType: farmAnimalType },
      },
    ]);
  };

  const stepClicked = (id) => {
    setActiveStep(Number(id));
  };

  useEffect(() => {
    if (farmID && datasetID) {
      getFarmAnimalType(farmID, stateAnimalType).then((farmAnimalType) => {
        setAnimalType(farmAnimalType);
        if (farmAnimalType) {
          fetchPPDatasetV2ForInterventions(
            farmID,
            datasetID,
            farmAnimalType,
            setBaselineData,
            (error) => console.log(error)
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetID, farmID]);

  const saveSuccessHandler = () => {
    setNotification({
      message: intl.formatMessage({ id: "INTERVENTION.SAVE.SUCCESS" }),
      type: NotificationDialogType.INFO,
    });
  };

  const saveFailedHandler = (errorMessage) => {
    setNotification({
      message: errorMessage,
      type: NotificationDialogType.ERROR,
    });
  };

  const onErrors = (errors) => {
    console.log("fromvalues", methods.getValues().stages);
    // console.log('manure:', methods.getValues().stages[0].stageData.manure);
    // console.log('feed:', methods.getValues().stages[0].stageData.feed);
    console.log(errors);
    const formErrors = Object.keys(methods.formState.errors).length;
    formErrorsEvent(
      formType === "add" ? "Add Baseline" : "Edit Baseline",
      formType,
      formErrors
    );
  };

  const onSubmit = (submitData) => {
    submitData.stages?.forEach((item, itemIndex) => {
      if (baseline?.stages?.[itemIndex]?.stageData?.input?.startDate)
        item.stageData.input.startDate =
          baseline?.stages?.[itemIndex]?.stageData?.input?.startDate;
      if (baseline?.stages?.[itemIndex]?.stageData?.input?.endDate)
        item.stageData.input.endDate =
          baseline?.stages?.[itemIndex]?.stageData?.input?.endDate;
    });
    // Don't allow save while there is error for calc changed
    if (checkFor3NOPError()) {
      return;
    }
    const payloadObj = mapInterventionDataOutSustell(submitData, animalType);
    // console.log("payload", JSON.stringify(payloadObj, null, 2));
    // return
    saveIntervention(
      formType,
      customerID,
      farmID,
      processID,
      payloadObj,
      animalType,
      saveSuccessHandler,
      saveFailedHandler,
      formSubmissionEvent,
      submitData.info?.oldName
    );

    if (draftKey) {
      dispatch(clearInterventionDraftAction({ draftKey }));
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react/destructuring-assignment
    if (props.location?.state?.interventionStep && baseline) {
      // eslint-disable-next-line react/destructuring-assignment
      setActiveStep(props.location?.state?.interventionStep);
    }
    // eslint-disable-next-line react/destructuring-assignment
  }, [props.location?.state?.interventionStep, baseline]);

  const methods = useForm({
    context: { animalContext: animalType || "" }, // must be passed as parameter, or available in component
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: (data, context) => {
      const { animalContext } = context;
      return yupResolver(
        getValidationSchemaSustell({
          animalContext: animalContext,
          intl: intl,
          minValueOutput: minValueOutput,
          ...tempRange,
          userUOM: userUOM,
          baseline,
        })
      )(data);
    },
    shouldUnregister: false,
    defaultValues: defaultValues[animalType],
  });

  // set additional resources for interventions
  useEffect(() => {
    if (
      baseline &&
      Object.keys(baseline).length > 0 &&
      intervention &&
      Object.keys(intervention).length > 0 &&
      animalType
    ) {
      const newIntervention = JSON.parse(JSON.stringify(intervention));
      let changed = false;
      if (isVersionV2AnimalSystem(animalType)) {
        if (!newIntervention.resourceUse?.selfGeneratedRenewables) {
          newIntervention.resourceUse.selfGeneratedRenewables = [];
        }
        if (!newIntervention.resourceUse?.energyTypes) {
          newIntervention.resourceUse.energyTypes = [];
        }

        lookupValues.selfGeneratedRenewablesList.forEach((item) => {
          if (
            !baseline.resourceUse.selfGeneratedRenewables.find(
              (ren) => ren.type === item.fieldName
            ) &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            Object.keys(newIntervention.resourceUse).includes(item.fieldName) &&
            newIntervention.resourceUse?.selfGeneratedRenewables.findIndex(
              (it) => it.type === item.fieldName
            ) === -1
          ) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            const value = Object.entries(newIntervention.resourceUse).find(
              ([key]) => key === item.fieldName
            )?.[1];
            newIntervention.resourceUse.selfGeneratedRenewables.push({
              value,
              type: item.fieldName,
            });
            delete newIntervention.resourceUse[item.fieldName];
            delete newIntervention.resourceUse[
              `${item.fieldName}_changeMetric`
            ];
            changed = true;
          }
        });
        lookupValues.energyTypesList.forEach((item) => {
          if (
            !baseline.resourceUse.energyTypes.find(
              (ren) => ren.type === item.fieldName
            ) &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            Object.keys(newIntervention.resourceUse).includes(item.fieldName) &&
            newIntervention.resourceUse?.energyTypes.findIndex(
              (it) => it.type === item.fieldName
            ) === -1 &&
            !(isMarineFish(animalType) && item.fieldName === "dieselUse")
          ) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            const value = Object.entries(newIntervention.resourceUse).find(
              ([key]) => key === item.fieldName
            )?.[1];
            newIntervention.resourceUse.energyTypes.push({
              value,
              type: item.fieldName,
            });
            delete newIntervention.resourceUse[item.fieldName];
            delete newIntervention.resourceUse[
              `${item.fieldName}_changeMetric`
            ];
            changed = true;
          }
        });
      }

      if (changed) {
        // console.log('intervention', intervention);
        // console.log('newIntervention', newIntervention);
        methods.reset(newIntervention);
        setIntervention(newIntervention);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseline, intervention, animalType]);

  const setInterventionData = (fetchedIntervention, farmAnimalType) => {
    // console.log('FETCHED INTERVNETION ', fetchedIntervention, farmAnimalType);
    const selIntervention = mapInterventionDataInSustell(
      fetchedIntervention,
      farmAnimalType
    );

    // console.log('LOADED INTERVNETION ', selIntervention);

    // TODO: check on this and
    // if (baseline) {
    //   selIntervention.stages.forEach((stage, i) => {
    //     if (baseline?.stages && baseline.stages.length > i) {
    //       const baselineStageInput = baseline?.stages[i]?.stageData?.input;
    //       if (baselineStageInput?.startDate && stage.stageData?.input) {
    //         stage.stageData.input.startDate = baselineStageInput.startDate;
    //       }
    //       if (baselineStageInput?.endDate && stage.stageData?.input) {
    //         stage.stageData.input.endDate = baselineStageInput.endDate;
    //       }
    //       console.log('stage', stage.stageData.input)
    //     }
    //   })
    // }

    // If it's du[licate remove id, oldname and change the current name
    // also geenrate new ID's for stages, also clear impact summary
    if (duplicateInterventionRef) {
      selIntervention.id = undefined;
      selIntervention.oldName = undefined;
      selIntervention.info.name += " - COPY";
      selIntervention.stages.forEach((stage) => {
        const currStage = stage;
        currStage.id = uuidv4();
      });
      selIntervention.impactSummary = undefined;
    }
    methods.reset(selIntervention);
    setIntervention(selIntervention);
    subheader.setBreadcrumbs([
      {
        pathname: `/customers/${customerID}/myfarms`,
        title: intl.formatMessage({ id: "SUSTELL.MY_FARMS" }),
        state: { animalType },
      },
      {
        pathname: `/customers/${customerID}/farm/${farmID}`,
        title: farmName,
        state: { animalType },
      },
      {
        pathname: `/customers/${customerID}/farm/${farmID}/pp/${processID}`,
        title: processName,
        state: { animalType },
      },
      {
        pathname: `/customers/${customerID}/farm/${farmID}/pp/${processID}/nint/${interventionID}`,
        title:
          selIntervention?.info?.name ||
          intl.formatMessage({ id: "GENERAL.NEW.INTERVENTION" }),
        state: { animalType },
      },
    ]);
  };

  const closeNotificationDialog = (stayOnPage) => {
    setNotification(null);
    if (!stayOnPage) {
      setTimeout(() => {
        methods.reset(methods.getValues());
        const key = createDraftKey();
        dispatch(clearInterventionDraftAction({ key }));
        history.push(`/customers/${customerID}/farm/${farmID}/pp/${processID}`);
      }, 700);
    }
  };

  const forwardStep = (completed) => {
    if (completed) {
      const currentlyCompleted = [...completedSteps];
      if (currentlyCompleted.some((item) => item !== `${activeStep}`)) {
        currentlyCompleted.push(`${activeStep}`);
        // console.log('currentlyCompleted', currentlyCompleted);
        setCompletedSteps([...currentlyCompleted]);
      }
    }
    setActiveStep(activeStep + 1);
  };

  const handleCancel = () => {
    history.push(`/customers/${customerID}/farm/${farmID}/pp/${processID}`, {
      farmName,
    });
  };

  const getFacilityType = (facilityId) =>
    facilities.find((f) => f.id === facilityId)?.type;

  // Set facility type on processing stages after baseline and facilities are loaded
  useEffect(() => {
    if (baseline && baseline.stages && facilities) {
      baseline.stages.forEach((stage, index) => {
        if (stage.type === "PROCESSING") {
          // eslint-disable-next-line no-param-reassign
          stage.facilityType = getFacilityType(stage.facilityId);
          methods.setValue(`stages[${index}].type`, "PROCESSING");
          methods.setValue(`stages[${index}].facilityId`, stage.facilityId);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseline, facilities, intervention]);

  useEffect(() => {
    // TODO, Update code with interventions backend
    // if (baseline) {
    const interventionKeyFromDraft = createDraftKey();
    const existingDraft = getInterventionDraftByKey(interventionKeyFromDraft);
    getFarmAnimalType(farmID, stateAnimalType).then((farmAnimalType) => {
      setAnimalType(farmAnimalType);
      if (existingDraft) {
        // opens restore drafts dialog, depending on result it will  load data from draft storage or skip it
        setOpenDraftDialog(true);
      } else if (farmID && interventionID && interventionID !== "NEW")
        fetchInterventionDatasetForInterventions(
          farmID,
          interventionID,
          farmAnimalType,
          setInterventionData,
          (error) => console.log(error)
        );
      else if (farmID && interventionID === "NEW" && duplicateInterventionRef)
        fetchInterventionDatasetForInterventions(
          farmID,
          duplicateInterventionRef,
          farmAnimalType,
          setInterventionData,
          (error) => console.log(error)
        );
    });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interventionID, farmID, duplicateInterventionRef]);

  // TODO: check if needed, remove
  const getCompleteness = (getCurrentValues, fieldGroup) => {
    // target object will be read from defaultValues
    const groupData = getCurrentValues(fieldGroup);

    let targetObject = defaultValues[animalType]
      ? defaultValues[animalType][fieldGroup]
      : {};
    if (fieldGroup.indexOf("stageData") !== -1) {
      const groupPath = fieldGroup.substring(
        fieldGroup.indexOf("stageData") + 10
      );
      targetObject =
        defaultValues[animalType]?.stages[0]?.stageData[groupPath] || {};
    }
    if (
      typeof groupData === "object" &&
      Object.keys(groupData).length > 0 &&
      typeof targetObject === "object" &&
      Object.keys(targetObject).length > 0
    ) {
      const keys = Object.keys(targetObject);
      let totalFields = keys.length;
      let completed = 0;
      keys.forEach((key) => {
        // count filled in the lists - dynamic items
        if (Array.isArray(targetObject[key])) {
          if (targetObject[key].length > 0) {
            // decrease number of total fields, as it will be fixed with exact numebr of subfields
            totalFields -= 1;
            const defaultCount = Object.keys(targetObject[key][0]).length;
            // if any data for the list entered, count it
            if (groupData[key] && groupData[key].length > 0) {
              groupData[key].forEach((item) => {
                // for each list item, inceraase number of total fields
                totalFields += defaultCount;
                // count filled fields
                const subkeys = Object.keys(item);
                subkeys.forEach((subkey) => {
                  if (item[subkey] || item[subkey] === 0) completed += 1;
                });
              });
            }
            // non of items is inserted, just increase number of total fields
            else totalFields += defaultCount;
          }
          // in case list of strings (e.g. products), chack is empty or not
          else {
            if (groupData[key] && groupData[key].length > 0) completed += 1;
          }
        }
        // if nested object, for now one level supported
        else if (
          typeof targetObject[key] === "object" &&
          Object.keys(targetObject[key]).length > 0 &&
          typeof groupData[key] === "object" &&
          Object.keys(groupData[key]).length > 0
        ) {
          // decrease number of total fields, as it will be fixed with exact numebr of subfields
          totalFields -= 1;
          Object.keys(targetObject[key]).forEach((subkey) => {
            totalFields += 1;
            if (groupData[key][subkey] || groupData[key][subkey] === 0)
              completed += 1;
          });
        }
        // single value
        else if (groupData[key] || groupData[key] === 0) {
          completed += 1;
        }
      });
      return Math.round((completed / totalFields) * 100);
    }
    return 0;
  };

  const discardDraftFlag = useRef(false);

  const saveDraft = (key) => {
    const formValues = methods.getValues();

    if (key) {
      const payloadObj = mapInterventionDataOutSustell(
        formValues,
        animalType,
        true
      ); // third param is isDraft - for interventions needed actually only for Dairy
      dispatch(
        setInterventionDraftAction({ key, newInterventionValue: payloadObj })
      );
    }
  };

  useEffect(() => {
    // console.log("form state changed");
    if (
      Object.keys(methods.formState.touched).length > 0 &&
      !discardDraftFlag.current
    ) {
      saveDraft(draftKey);
    }
    if (discardDraftFlag.current) discardDraftFlag.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.formState]);

  const restoreDraft = () => {
    try {
      // when drafts activated
      if (draftKey) {
        const draft = getInterventionDraftByKey(draftKey);
        if (!draft) {
          setOpenDraftDialog(false);
          return;
        }
        // first remove all form vals
        // methods.reset(emptyFormDefaults);

        // then set all from draft
        // console.log("calling restore draft");
        const selIntervention = mapInterventionDataInSustell(
          draft,
          animalType,
          true
        ); // last parametar set to true to indicate draft loading (needed for dairy 3NOP handling)

        setOpenDraftDialog(false);
        setIntervention(selIntervention);
        methods.reset(selIntervention);
        subheader.setBreadcrumbs([
          {
            pathname: `/customers/${customerID}/myfarms`,
            title: intl.formatMessage({ id: "SUSTELL.MY_FARMS" }),
            state: { animalType },
          },
          {
            pathname: `/customers/${customerID}/farm/${farmID}`,
            title: farmName,
            state: { animalType },
          },
          {
            pathname: `/customers/${customerID}/farm/${farmID}/pp/${processID}`,
            title: processName,
            state: { animalType },
          },
          {
            pathname: `/customers/${customerID}/farm/${farmID}/pp/${processID}/nint/${interventionID}`,
            title:
              selIntervention?.info?.name ||
              intl.formatMessage({ id: "GENERAL.NEW.INTERVENTION" }),
            state: { animalType },
          },
        ]);
      }
    } catch (err) {
      setRestoreDraftErrorMessage(
        `${intl.formatMessage({ id: "SUSTELL.ERROR.RESTORING_DRAFT" })}${
          err?.message
        }`
      );
      console.error(err);
    }
  };

  const closeDraftDialog = async () => {
    const key = createDraftKey();
    dispatch(clearInterventionDraftAction({ key }));
    setOpenDraftDialog(false);
    setRestoreDraftErrorMessage("");

    const farmAnimalType = await getFarmAnimalType(farmID, stateAnimalType);
    console.log(
      "farmAnimalType",
      farmAnimalType,
      farmID,
      interventionID,
      duplicateInterventionRef
    );
    setAnimalType(farmAnimalType);
    if (farmID && interventionID && interventionID !== "NEW")
      fetchInterventionDatasetForInterventions(
        farmID,
        interventionID,
        farmAnimalType,
        setInterventionData,
        (error) => console.log(error)
      );
    else if (farmID && interventionID === "NEW" && duplicateInterventionRef)
      fetchInterventionDatasetForInterventions(
        farmID,
        duplicateInterventionRef,
        farmAnimalType,
        setInterventionData,
        (error) => console.log(error)
      );
  };

  return (
    <>
      <DsmModal
        open={openDraftDialog}
        header={intl.formatMessage({ id: "GENERAL.UNSAVED_CHANGES" })}
        icon="general/info-square"
      >
        <p slot="content">
          {intl.formatMessage({ id: "GENERAL.DRAFT.INFO.MESSAGE" })}
        </p>
        {restoreDraftErrorMessage && (
          <p slot="content" style={{ color: "var(--dsm-color-error)" }}>
            {restoreDraftErrorMessage}
          </p>
        )}
        <DsmButtonV2
          variant="secondary"
          slot="actions"
          onClick={closeDraftDialog}
          className={buttonClasses?.secondaryButton}
        >
          {intl.formatMessage({ id: "GENERAL.DRAFT.ACTION.DISCARD" })}
        </DsmButtonV2>
        <DsmButtonV2 variant="secondary" slot="actions" onClick={restoreDraft}>
          {intl.formatMessage({ id: "GENERAL.DRAFT.ACTION.RESTORE" })}
        </DsmButtonV2>
      </DsmModal>

      <NotificationDialog
        open={!!notification}
        message={notification?.message}
        type={notification?.type}
        closeNotificationDialog={() =>
          closeNotificationDialog(
            notification?.type !== NotificationDialogType.INFO
          )
        }
      />

      <Typography variant='h4' style={{ color: '#003478', fontWeight: 500, }}>
        {intl.formatMessage({ id: "GENERAL.NEW.INTERVENTION" })}
      </Typography>
      <Typography variant='body1' color='textPrimary' style={{ marginBottom: '2rem' }}>
        {activeStep === 1 ? intl.formatMessage({ id: "SUSTELL.CURRENT.INTERVENTION.DESIGN.PROCESS.DESCRIPTION" })
          : intl.formatMessage({ id: "SUSTELL.CURRENT.INTERVENTION.DESIGN.PROCESS.STAGE_DESCRIPTION" })}
      </Typography>

      <SustellInterventionSteps
        active={activeStep}
        completed={completedSteps}
        onStepChange={stepClicked}
      // customerID={customerID}
      // farmID={farmID}
      // processID={processID}
      // datasetID={datasetID}
      />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit, onErrors)}>
          <input
            ref={methods.register()}
            type="hidden"
            name="farmId"
            value={`${baseline?.farmId}`}
          />
          <input
            ref={methods.register()}
            type="hidden"
            name="baselineId"
            value={`${baseline?.id}`}
          />
          <input
            ref={methods.register()}
            type="hidden"
            name="productionProcessId"
            value={`${baseline?.productionProcessId}`}
          />
          <input
            ref={methods.register()}
            type="hidden"
            name="animalType"
            value={`${animalType}`}
          />
          {baseline && (
            <div hidden={activeStep !== 1}>
              <InterventionProfile
                baseline={baseline}
                formType={formType}
                intervention={intervention}
                animalType={animalType}
                forwardHandler={forwardStep}
                cancelHandler={handleCancel}
              />
            </div>
          )}
          {activeStep === 2 && isShrimp(animalType) && (
            <>
              <Typography variant='h6' style={{ color: 'black', fontWeight: 500, marginTop: `var(--dsm-spacing-px-20)`}}>
                {intl.formatMessage({ id: "SUSTELL.INTERVENTION.SHRIMP.PRODUCTION_PROCESS_DATA.TITLE" })}
              </Typography>
              <Typography variant='body1' color='textPrimary' style={{ marginBottom: '2rem' }}>
                {intl.formatMessage({ id: "SUSTELL.INTERVENTION.SHRIMP.PRODUCTION_PROCESS_DATA.DESCRIPTION" })}
              </Typography>
              <hr />
              <Typography variant='body1' color='textPrimary' style={{ marginBottom: '2rem' }}>
                {intl.formatMessage({ id: "SUSTELL.INTERVENTION.SHRIMP.STAGES.DESCRIPTION" })}
              </Typography>
            </>)
          }
          {baseline?.stages?.length > 0 &&
            baseline?.stages?.map((item, index) => (
              <div hidden={activeStep !== 2} key={`stages_steps_${item.id}`}>
                <Typography
                  variant="h6"
                  style={{ color: 'black', fontWeight: 500, }}
                >
                  {item.name ||
                    intl.formatMessage({
                      id: "SUSTELL.DEFAULT.STAGE_NAME",
                    })}{" "}
                  {item?.type && !item.facilityType ? `( ${item?.type} )` : ""}{" "}
                  {item?.facilityType
                    ? `( ${intl
                      .formatMessage({
                        id: `SUSTELL.STAGE.${item.facilityType}`,
                      })
                      .toUpperCase()} )`
                    : ""}{" "}
                </Typography>
                {animalType === "LAYING_HENS" && (
                  <LayingHensInterventionStages
                    baseline={baseline}
                    formType={formType}
                    stageIndex={index}
                    compoundFeeds={compoundFeeds}
                    singleIngredients={singleIngredients}
                    manureManagementSystems={manureManagementSystems}
                    intervention={intervention}
                  />
                )}
                {animalType === "BROILER" && (
                  <BroilerInterventionStages
                    baseline={baseline}
                    formType={formType}
                    stageIndex={index}
                    getCompleteness={getCompleteness}
                    compoundFeeds={compoundFeeds}
                    singleIngredients={singleIngredients}
                    manureManagementSystems={manureManagementSystems}
                    intervention={intervention}
                  />
                )}
                {/** FIX FOR PIG AND PIG_FATTENING PP  */}
                {animalType === "PIG_FATTENING" && (
                  <PigFatteningInterventionStages
                    baseline={baseline}
                    formType={formType}
                    stageIndex={index}
                    getCompleteness={getCompleteness}
                    compoundFeeds={compoundFeeds}
                    singleIngredients={singleIngredients}
                    manureManagementSystems={manureManagementSystems}
                    intervention={intervention}
                  />
                )}
                {animalType === "SALMON" && (
                  <SalmonInterventionStages
                    baseline={baseline}
                    formType={formType}
                    stageIndex={index}
                    compoundFeeds={compoundFeeds}
                    intervention={intervention}
                    masterDataEnums={masterDataEnums}
                  />
                )}
                {item.type === "PROCESSING" && isPig(animalType) && (
                  <SlaughterhouseInterventionStages
                    baseline={baseline}
                    intervention={intervention}
                    formType={formType}
                    stageIndex={index}
                    item={item}
                    stageType={item.type}
                    getCompleteness={getCompleteness}
                    facilities={facilities}
                    interventionID={interventionID}
                    customerID={customerID}
                    farmName={farmName}
                    processName={processName}
                  />
                )}
                {item.type !== "PROCESSING" && isPig(animalType) && (
                  <PigInterventionStages
                    farms={pigFarms}
                    farmId={farmID}
                    farmName={farmName}
                    productionProcessName={processName}
                    baseline={baseline}
                    intervention={intervention}
                    formType={formType}
                    stageIndex={index}
                    item={item}
                    stageType={item.type}
                    getCompleteness={getCompleteness}
                    compoundFeeds={compoundFeeds}
                    singleIngredients={singleIngredients}
                    origins={origins}
                  />
                )}
                {animalType === "DAIRY" && (
                  <DairyInterventionStages
                    baseline={baseline}
                    formType={formType}
                    stageIndex={index}
                    getCompleteness={getCompleteness}
                    compoundFeeds={compoundFeeds}
                    farmCountry={farmCountry}
                    set3NOPCalcChanged={set3NOPCalcChanged}
                    _3NOPCalcChanged={_3NOPCalcChanged}
                    singleIngredients={singleIngredients}
                    manureManagementSystems={manureManagementSystems}
                    intervention={intervention}
                  />
                )}
                {isMarineFish(animalType) && (
                  <MarineFishInterventionStages
                    baseline={baseline}
                    intervention={intervention}
                    formType={formType}
                    stageIndex={index}
                    item={item}
                    stageType={item.type}
                    getCompleteness={getCompleteness}
                    compoundFeeds={compoundFeeds}
                    singleIngredients={singleIngredients}
                    origins={origins}
                    masterDataEnums={masterDataEnums}
                  />
                )}
                {isShrimp(animalType) && (
                  <ShrimpInterventionStages
                    baseline={baseline}
                    intervention={intervention}
                    formType={formType}
                    stageIndex={index}
                    item={item}
                    stageType={item.type}
                    getCompleteness={getCompleteness}
                    compoundFeeds={compoundFeeds}
                    singleIngredients={singleIngredients}
                    origins={origins}
                    masterDataEnums={masterDataEnums}
                  />
                )}
                {item.type !== "PROCESSING" && isPoultry(animalType) && (
                  <PoultryInterventionStages
                    baseline={baseline}
                    formType={formType}
                    stageIndex={index}
                    item={item}
                    stageType={item.type}
                    getCompleteness={getCompleteness}
                    compoundFeeds={compoundFeeds}
                    singleIngredients={singleIngredients}
                    origins={origins}
                    animalType={animalType}
                    farms={poultryFarms}
                  />
                )}
                {item.type === "PROCESSING" && isPoultry(animalType) && (
                  <SlaughterhouseInterventionStages
                    baseline={baseline}
                    intervention={intervention}
                    formType={formType}
                    stageIndex={index}
                    item={item}
                    stageType={item.type}
                    getCompleteness={getCompleteness}
                    facilities={facilities}
                    interventionID={interventionID}
                    customerID={customerID}
                    farmName={farmName}
                    processName={processName}
                  />
                )}
              </div>
            ))}
          {activeStep === 2 && (
            <StepperButtons
              cancelHandler={() => {
                handleCancel();
              }}
              hideStandardContinue
              localSaveHandler={
                formType !== "view"
                  ? methods.handleSubmit(onSubmit, onErrors)
                  : undefined
              }
              localSaveLabel={intl.formatMessage({ id: "GENERAL.SAVE" })}
              continueHandler={undefined}
            />
          )}
        </form>
      </FormProvider>
    </>
  );
};

export default SustellInterventionMainPage_v2;
